<template>
  <v-card id="hlp-settings-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <v-col
      cols="12"
      style="padding-bottom:0px; margin-bottom:0px"
    >
      <v-alert
        v-show="alert.show"
        :color="alert.color"
        text
        class="mb-0"
      >
        <div class="d-flex align-start">
          <v-icon :color="alert.color">
            {{ icons.mdiAlertOutline }}
          </v-icon>

          <div class="ms-3">
            <p class="text-base font-weight-medium mb-1 justify-right">
              {{ alert.message }}
            </p>
            <a
              align="right"
              href="javascript:void(0)"
              title="Hide this message"
              :class="alert.class"
              @click.prevent="hideAlert"
            >
              <span class="text-sm">{{ alert.callToAction }}</span>
            </a>
          </div>
        </div>
      </v-alert>
    </v-col>

    <!-- loading icon -->
    <div
      v-show="isLoading"
      id="misc"
    >
      <div class="page-title text-center justify-center px-5">
        <div class="misc-character d-flex justify-center">
          <v-img
            max-width="100"
            margin="100"
            src="@/assets/images/misc/loading-spinner-dark.gif"
          ></v-img>
        </div>
      </div>
    </div>

    <!-- tabs item -->
    <v-tabs-items
      v-show="!isLoading"
      v-model="tab"
    >
      <v-tab-item>
        <hosted-login-page-settings-general
          :tenant-data="tenantData"
          :logo-url="logoUrl"
          :is-logo-loading="isLogoLoading"
          @validate-event="validate"
          @refresh-logo="updateLogoWithDelay"
        ></hosted-login-page-settings-general>
      </v-tab-item>
    </v-tabs-items>

    <v-card
      flat
      class="pa-0 mt-2"
    >
      <v-card-text>
        <v-form class="multi-col-validation mt-6">
          <v-row>
            <v-col cols="6">
              <v-btn
                color="primary"
                class="me-3 mt-4"
                :disabled="!isFormValid || !valid || isLoading"
                @click="saveTenant"
              >
                {{ uiMode === 'account' ? 'Save & Exit' : 'Save' }}
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
                :disabled="isLoading"
                @click.prevent="exit"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              class="text-right"
            >
              <v-btn
                color="error"
                outlined
                class="me-3 mt-4"
                type="reset"
                @click="deleteItem()"
              >
                Reset to Defaults
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- Reset Confirmation Dialog -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h6">
          Are you sure you want to reset to defaults?
        </v-card-title>
        <v-card-text>
          The colors for the Hosted Login Page are being reset to default values and the logo is being removed.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            text
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-btn
            color="blue darken-1"
            outlined
            text
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END: Reset Confirmation Dialog -->
  </v-card>
</template>

<script>
import {
  mdiAccountOutline, mdiLockOpenOutline, mdiInformationOutline, mdiRobotOutline,
  mdiFingerprint, mdiWavesArrowRight, mdiAutoFix, mdiFormSelect, mdiBadgeAccountOutline,
  mdiBadgeAccountAlertOutline, mdiProtocol, mdiLockOutline, mdiKeyChain, mdiHome, mdiFileKeyOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import * as QuasrHelper from '@quasr-io/helper'
import gql from 'graphql-tag'
import { getTenant } from '../../graphql/queries'
import { updateTenant } from '../../graphql/mutations'
import HostedLoginPageSettingsGeneral from './HLPSettingsGeneral.vue'
import store from '../../store'

export default {

  /**
   * components
   */
  components: {
    HostedLoginPageSettingsGeneral,
  },

  /**
   * data
   */
  data() {
    if (!this.loginStatus.logged_in) {
      this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    }

    return {
      valid: true,
      mode: this.$route.params.id === 'new' ? 'create' : 'update',
      alert: {
        show: false,
        message: '',
        callToAction: 'OK',
        color: 'success',
        class: 'text-decoration-none success--text pointer',
      },
      deleteLogo: false,
      subscriptions: [],
      controls: [],
      consents: [],
      permissions: [],
      consent_controls: [],
      permission_controls: [],
      dialogDelete: false,
      dialog: false,
      logoUrl: '',
      tenantData: {
        type: 'tenant',
        account: this.loginStatus.sub,
        status: 'ENABLED',
        config: {
          hlp: {},
          color: '#444444',
          login_session_expiration: '1d',
          signup_session_expiration: '15m',
          inactive_tenant_expiration: '90d',
          inactive_account_expiration: '90d',
        },
      },
    }
  },

  /**
   * computed
   */
  computed: {

    computedTenantColor() {
      return this.tenantData?.config?.color
    },

    /**
     * isFormValid
     */
    isFormValid() {
      this.validate()

      const missingFields = []
      if (this.tenantData.config.hlp) {
        const keys = ['background_color', 'background_text_color', 'background_link_color', 'widget_color', 'widget_text_color', 'widget_link_color']
        for (let i = 0; i < keys.length; i += 1) {
          const key = keys[i]
          // eslint-disable-next-line
          if (!/^(?:#)([a-f0-9]{6})\b|\([\)]*\)/ig.test(this.tenantData.config.hlp[key])) missingFields.push(keys[i])
        }
      }
      QuasrHelper.log('Missing fields: '.concat(JSON.stringify(missingFields)))

      return missingFields.length === 0
    },
  },

  /**
   * created
   */
  async created() {
    if (!this.loginStatus.logged_in) this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/`)
    this.isLoading = true
    await this.getTenant()
    this.isLoading = false
  },

  /**
   * methods
   */
  methods: {
    /**
     * validate
     */
    async validate() {
      let result = true
      if (this.tenantData.config.hlp) {
        // this.tenantData.config.hlp = JSON.parse(JSON.stringify(this.tenantData.config.hlp))

        const keys = ['background_color', 'background_text_color', 'background_link_color', 'widget_color', 'widget_text_color', 'widget_link_color']
        for (let i = 0; i < keys.length; i += 1) {
          const key = keys[i]
          if (this.tenantData.config.hlp[key]) {
            this.tenantData.config.hlp[key] = JSON.parse(JSON.stringify(this.tenantData.config.hlp))[key]
            this.tenantData.config.hlp[key] = this.tenantData.config.hlp[key].toUpperCase()
            if (!this.tenantData.config.hlp[key].startsWith('#')) {
              this.tenantData.config.hlp[key] = '#'.concat(this.tenantData.config.hlp[key])
            }
          }
          // eslint-disable-next-line
          if (!/^(?:#)([a-f0-9]{6})\b|\([\)]*\)/ig.test(this.tenantData.config.hlp[key])) {
            result = false
          }
        }
      }

      if (this.tenantData.config.hlp.new_logo) {
        const file = this.tenantData.config.hlp.new_logo
        if (file.size > 300000 || (!file.name.toLowerCase().endsWith('.jpg') && !file.name.toLowerCase().endsWith('.jpeg') && !file.name.toLowerCase().endsWith('.png'))) {
          result = false
        }
      }
      this.valid = result

      return result
    },

    /**
     * closeDelete
     */
    closeDelete() {
      this.dialogDelete = false
    },

    /**
     * deleteItem
     */
    deleteItem() {
      this.dialogDelete = true
    },

    /**
     * deleteItemConfirm
     */
    async deleteItemConfirm() {
      this.dialogDelete = false
      await this.reset()
    },

    /**
     *
     */
    setDefaults() {
      this.tenantData.config.hlp = {
        logo_new: null,
        delete_logo: true,
        background_color: '#FDFDFD',
        background_text_color: '#5E5669',
        background_link_color: '#3C78D8',
        widget_color: '#FFFFFF',
        widget_text_color: '#5E5669',
        widget_link_color: '#3C78D8',
      }
    },

    /**
     * reset
     */
    async reset() {
      this.setDefaults()
      await this.saveTenant()
    },

    /**
     * getTenant
     */
    async getTenant() {
      try {
        const tenant = await store.getAppSyncClient().query({ query: gql(getTenant), variables: { id: this.$route.params.id } })
        this.tenantData = tenant.data.getTenant
        if (!tenant.data.getTenant.config?.hlp || !tenant.data.getTenant.config?.hlp?.background_color) {
          tenant.data.getTenant.config.hlp = {}
          this.setDefaults()
        }
        this.tenantColor = this.tenantData.config?.color || '#444444'
        this.logoUrl = this.tenantData.config?.hlp?.logo
      } catch (err) {
        QuasrHelper.log(err)
        const e = JSON.parse(JSON.stringify(err))
        if (e.status === 401 || e.networkError?.statusCode === 401) {
          localStorage.removeItem(`${this.QUASR_ENV.tenant_id}.exp`)
          this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/login`)
        } else {
          this.exit()
        }
      }
    },

    /**
     * showAlert
     */
    showAlert(message, callToAction, color) {
      this.alert.message = message
      this.alert.callToAction = callToAction
      this.alert.show = true
      this.alert.color = color
      this.alert.class = 'text-decoration-none '.concat(color).concat('--text')
    },

    /**
     * hideAlert
     */
    hideAlert() {
      this.alert.show = false
    },

    /**
     * exit
     */
    exit() {
      this.$router.back()
    },

    /**
     * saveTenant
     */
    async saveTenant() {
      this.isLoading = true
      this.isLogoLoading = true
      const obj = {
        id: this.tenantData.id,
        config: {
          hlp: {
            background_color: this.tenantData?.config?.hlp?.background_color,
            background_text_color: this.tenantData?.config?.hlp?.background_text_color,
            background_link_color: this.tenantData?.config?.hlp?.background_link_color,
            widget_color: this.tenantData?.config?.hlp?.widget_color,
            widget_text_color: this.tenantData?.config?.hlp?.widget_text_color,
            widget_link_color: this.tenantData?.config?.hlp?.widget_link_color,
          },
        },
      }
      obj.id = this.tenantData.id

      // logo
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })

      if (this.tenantData.config.hlp.new_logo) {
        const file = this.tenantData.config.hlp.new_logo
        const file_base64 = await toBase64(file)
        if (file.size > 300000 || (!file.name.toLowerCase().endsWith('.jpg') && !file.name.toLowerCase().endsWith('.jpeg') && !file.name.toLowerCase().endsWith('.png'))) {
          this.isLoading = false

          return
        }
        obj.config.hlp.logo = file_base64
      } else if (this.tenantData.config.hlp.delete_logo) {
        obj.config.hlp.logo = null // delete current logo
      } else {
        delete obj.config.hlp.logo // do nothing about the current logo (keep existing)
      }

      try {
        const cmd = await store.getAppSyncClient().mutate({ mutation: gql(updateTenant), variables: { input: obj } })
        if (cmd.data.updateTenant) {
          this.showAlert('Hosted Login Page configuration saved successfully.', 'OK', 'success')
          this.tenantData = JSON.parse(JSON.stringify(cmd.data.updateTenant))
          if (this.tenantData.config.hlp.logo) {
            this.isLogoLoading = true
            this.tenantData.config.hlp.logo = cmd.data.updateTenant.config.hlp.logo.concat('?').concat(new Date().getTime())
            this.updateLogoWithDelay()
          } else {
            this.isLogoLoading = false
          }
        } else {
          this.showAlert('Saving Hosted Login Page configuration failed.', 'OK', 'error')
        }
      } catch (err) {
        this.showAlert(`Saving Hosted Login Page configuration failed. ${err.message}`, 'OK', 'error')
      } finally {
        this.isLoading = false
      }
    },

    /**
     * logout
     */
    logout() {
      localStorage.clear()
      this.$router.push(`/${this.QUASR_ENV.tenant_id}/${this.QUASR_ENV.client_id}/logout`)
    },

    /**
     * updateLogoWithDelay
     */
    updateLogoWithDelay() {
      this.logoUrl = ''
      this.isLogoLoading = true
      setTimeout(() => {
        this.logoUrl = this.tenantData.config.hlp.logo.concat('?').concat(new Date().getTime())
        this.isLogoLoading = false
      }, 5000) // wait 5 secs for CDN invalidation to complete
    },
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const API_ENV = QuasrHelper.getApiEnv()
    const QUASR_ENV = QuasrHelper.getTenantAndClient()
    // eslint-disable-next-line
    const QUASR_ROOT_TENANT = QuasrHelper.ID.QUASR_ROOT_TENANT
    const uiMode = document.location.hostname.startsWith('account') ? 'account' : 'admin'
    const loginStatus = QuasrHelper.checkLoginStatus(QUASR_ENV.tenant_id)
    const tab = ref('')
    const isLoading = true
    const isLogoLoading = false

    // tabs
    const tabs = [
      { title: 'Hosted Login Page', icon: mdiFileKeyOutline },
    ]

    return {
      ENV,
      API_ENV,
      QUASR_ENV,
      QUASR_ROOT_TENANT,
      uiMode,
      isLogoLoading,
      isLoading,
      loginStatus,
      tab,
      tabs,
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiRobotOutline,
        mdiFingerprint,
        mdiBadgeAccountAlertOutline,
        mdiBadgeAccountOutline,
        mdiWavesArrowRight,
        mdiAutoFix,
        mdiFormSelect,
        mdiProtocol,
        mdiLockOutline,
        mdiKeyChain,
        mdiHome,
        mdiFileKeyOutline,
      },
    }
  },
}
</script>
