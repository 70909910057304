<template>
  <v-card
    flat
    class="pa-0 mt-2"
  >
    <v-card-title class="flex-nowrap">
      <v-icon class="text--primary me-3">
        {{ icons.mdiFileKeyOutline }}
      </v-icon>
      <span class="text-break">Hosted Login Page</span>
    </v-card-title>

    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="8"
            cols="12"
          >
            <v-file-input
              v-model="tenantData.config.hlp.new_logo"
              :rules="logoRule"
              label="Logo (.jpg or .png, max. 300kB, max. 440x440px)"
              accept="image/jpg, image/jpeg, image/png"
              chips
              counter
              show-size
              truncate-length="80"
              @change="validate()"
            ></v-file-input>
            The recommended logo size is width: 100-130px, height: 50-80px. Note that it can take a few seconds that a newly uploaded logo shows here and on the hosted login page due to CDN caching.
          </v-col>
          <v-col
            md="4"
            cols="12"
          >
            <div>
              <div
                v-if="tenantData.config.hlp.logo"
                :style="`background-color:${tenantData.config.hlp.widget_color}; text-align:center;padding:10px;`"
              >
                <img
                  v-if="logoUrl"
                  id="logo"
                  :src="logoUrl"
                  style="max-height:80px;max-width:200px"
                />
                <div
                  v-if="!logoUrl && isLogoLoading"
                  class="misc-character d-flex justify-center"
                >
                  <v-img
                    max-width="100"
                    margin="100"
                    src="@/assets/images/misc/loading-spinner-dark.gif"
                  ></v-img>
                </div>
              </div>
            </div>
            <center v-if="logoUrl && tenantData.config.hlp.logo">
              <small>Still showing the previous logo? <a @click="triggerLogoRefresh()">Refresh</a></small>
            </center>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="tenantData.config.hlp.background_color"
              hide-details="auto"
              label="Background Color (#RRGGBB)"
              dense
              outlined
              :rules="rgbRule"
              @keydown="validate()"
              @keypress="validate()"
              @change="validate()"
            ></v-text-field>
          </v-col>
          <v-col
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="tenantData.config.hlp.background_text_color"
              hide-details="auto"
              label="Background Text Color (#RRGGBB)"
              dense
              outlined
              :rules="rgbRule"
              @keydown="validate()"
            ></v-text-field>
          </v-col>
          <v-col
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="tenantData.config.hlp.background_link_color"
              hide-details="auto"
              label="Background Link Color (#RRGGBB)"
              dense
              outlined
              :rules="rgbRule"
              @keydown="validate()"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="tenantData.config.hlp.widget_color"
              hide-details="auto"
              label="Widget Color (#RRGGBB)"
              dense
              outlined
              :rules="rgbRule"
              @keydown="validate()"
            ></v-text-field>
          </v-col>
          <v-col
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="tenantData.config.hlp.widget_text_color"
              hide-details="auto"
              label="Widget Text Color (#RRGGBB)"
              dense
              outlined
              :rules="rgbRule"
              @keydown="validate()"
            ></v-text-field>
          </v-col>
          <v-col
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="tenantData.config.hlp.widget_link_color"
              hide-details="auto"
              label="Widget Link Color (#RRGGBB)"
              dense
              outlined
              :rules="rgbRule"
              @keydown="validate()"
            ></v-text-field>
          </v-col>
        </v-row>

        <!--
        background_color?: string
        background_text_color?: string
        background_link_color?: string
        widget_color?: string
        widget_text_color?: string
        widget_link_color?: string
        logo?: string
        -->

        <v-row>
          <!--
          <v-col
            cols="12"
            md="6"
          >
            <div style="margin-bottom:10px">
              Color Code:
            </div>
            <v-color-picker
              v-model="color"
              type="hex"
              mode="hexa"
            ></v-color-picker>
            <div><small>You can color-code a tenant to easily distinguish<br />it from others in the Tenant Admin UI.</small></div>
          </v-col>
          -->
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline, mdiEyeOffOutline, mdiEyeOutline, mdiFormSelect, mdiHome, mdiContentCopy, mdiFileKeyOutline,
} from '@mdi/js'
import * as QuasrHelper from '@quasr-io/helper'

export default {

  /**
   * props
   */
  props: {

    /**
     * tenantData
     */
    tenantData: {
      type: Object,
      default: () => {},
    },

    tenantColor: {
      type: String,
      default: '',
    },

    logoUrl: {
      type: String,
      default: '',
    },

    /**
     * subscriptions
     */
    subscriptions: {
      type: Array[Object],
      default: () => [],
    },

    /**
     * consent_controls
     */
    consentControls: {
      type: Array[Object],
      default: () => [],
    },

    /**
     * permission_controls
     */
    permissionControls: {
      type: Array[Object],
      default: () => [],
    },

    /**
     * scopes
     */
    controls: {
      type: Array[Object],
      default: () => [],
    },

    /**
     * isLogoLoading
     */
    isLogoLoading: {
      type: Boolean,
      default: false,
    },

    /**
     * mode
     */
    mode: { type: String, default: undefined },
  },

  /**
   * data
   */
  data() {
    return {
      tenantColorLocal: this.tenantColor,
      inputFieldRules: [
        value => !!value || 'Required',
      ],
      requiredRule: [
        value => !!value || 'Required',
      ],
      rgbRule: [
        // eslint-disable-next-line
        value => /^(?:#)([a-f0-9]{6})\b|\([\)]*\)/ig.test(value) || 'Hex color code in format #RRGGBB required',
      ],
      logoRule: [
        value => !value || value.size < 300000 || 'Logo should be a .png or .jpg and not more than 300 kB in size',
      ],
      type: 'hex',
      hex: this.tenantColor,
      hexa: `${this.tenantColor}`,
    }
  },

  computed: {
    subscriptionLabel(item) {
      return item.metadata.nickname.concat(' ').concat(item.id)
    },
    color: {
      get() {
        return this.tenantColorLocal
      },
      set(v) {
        this.tenantColorLocal = v
      },
    },
    showColor() {
      if (typeof this.color === 'string') return this.color

      return JSON.stringify(Object.keys(this.color).reduce((color, key) => {
        this.color[key] = Number(this.color[key].toFixed(2))

        return color
      }, {}), null, 2)
    },
  },

  /**
   * watch
   */
  watch: {
    /**
     * tenantColorLocal
     */
    tenantColorLocal() {
      this.$emit('tenant-color-change-event', this.tenantColorLocal)
    },

    /**
     * tenantColor
     */
    tenantColor() {
      this.tenantColorLocal = this.tenantColor
    },
  },

  /**
   * methods
   */
  methods: {
    /**
     * validate
     */
    validate() {
      this.$emit('validate-event', this.tenantData)
    },

    /**
     * triggerLogoRefresh
     */
    triggerLogoRefresh() {
      this.$emit('refresh-logo')
    },

    /**
     * openTenantAdminUI
     */
    openTenantAdminUI() {
      window.open(this.tenantAdminUrl())
    },

    /**
     * df
     */
    df(isoString) {
      return new Date(isoString).toLocaleString()
    },

    /**
     * cancel
     */
    cancel() {
      this.$router.back()
    },

    /**
     * idToClipboard
     */
    idToClipboard() {
      QuasrHelper.copyTextToClipboard(this.tenantData.id)
    },

    /**
     * adminUrlToClipboard
     */
    adminUrlToClipboard() {
      QuasrHelper.copyTextToClipboard(this.tenantAdminUrl())
    },

    /**
     * tenantAdminUrl
     */
    tenantAdminUrl() {
      if (this.tenantData?.admin_client) {
        const origin = document.location.origin.replace('account', 'admin')

        return `${origin}/${this.tenantData.id}/${this.tenantData.admin_client}`
      }

      return undefined
    },
  },

  /**
   * setup
   */
  setup() {
    const ENV = QuasrHelper.getEnv()
    const uiMode = document.location.hostname.startsWith('account') ? 'account' : 'admin'
    const status = [
      { text: 'Enabled', value: 'ENABLED' },
      { text: 'Disabled', value: 'DISABLED' },
    ]

    return {
      ENV,
      uiMode,
      status,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiFormSelect,
        mdiHome,
        mdiContentCopy,
        mdiFileKeyOutline,
      },
    }
  },
}
</script>
